import { ApiBoardConnector } from './ApiBoardConnector';
import { ApiStoriesConnector } from './ApiStoriesConnector';
import { ApiAuthenticationConnector } from './ApiAuthenticationConnector';
import { ApiMyConnector } from './ApiMyConnector';
import { ApiProjectConnector } from './ApiProjectConnector';
import { ApiSummariesConnector } from './ApiSummariesConnector';
import { ApiSeshatConnector } from './ApiSeshatConnector';
import { ApiDimensionsConnector } from './ApiDimensionsConnector';
import { ApiInstallersConnector } from './ApiInstallersConnector';
export class ApiConnector {
    constructor(client) {
        this.capabilities = {
            cards: {
                fullDetails: true,
                coverImage: true,
                attachments: true,
                actions: {
                    archive: true,
                    pin: true
                },
            },
            dimensions: {
                options: true,
                actions: {
                    disable: true,
                    delete: false,
                }
            },
            boards: {
                actions: {
                    save: true,
                    delete: true,
                },
            },
        };
        if (!client) {
            throw new Error('A KlaroClient is mandatory');
        }
        this.boards = new ApiBoardConnector(client);
        this.stories = new ApiStoriesConnector(client);
        this.dimensions = new ApiDimensionsConnector(client);
        this.auth = new ApiAuthenticationConnector(client);
        this.my = new ApiMyConnector(client);
        this.project = new ApiProjectConnector(client);
        this.summaries = new ApiSummariesConnector(client);
        this.installers = new ApiInstallersConnector(client);
        this.seshat = new ApiSeshatConnector(client);
    }
}
